export default {
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){
        let {client_type = 1, company_id = 0, coverage = {},class_id,notice,is_edit} = val||{}
        if(val){
          this.formData = {...this.formData,...val}
          let {cover_type} = coverage
          this.carTypeName = class_id||1001
          this.clientType = client_type
          this.insurer = company_id
          this.cover_type = cover_type
        }
        this.formData.notice = is_edit==1?notice:notice||this.remarkInit
      }
    }
  },
  computed:{
    remarkInit(){
      // return '此單最低消費HK$____。\n' +
      //     '如發生意外，必須於七日內向保險公司申報。\n' +
      //     'Minimum spending for this policy is HK$____.\n' +
      //     'In case of accident, it is a must to report to insurance company within 7 days.'
      return '* 此單最低消費HK$___。\n' +
          '* 如發生意外，必須於七日內向保險公司申報。\n' +
          '* 如有任何問題請致電本公司查詢。\n' +
          '* Minimum spending for this policy is HK$___.\n' +
          '* In case of accident, it is a must to report to insurance company within 7 days.\n' +
          '* If you have any questions, please feel free to call us.'
    }
  }
}
